import { text } from "data/text";
import { checkIsEmptyObjectOrArray, compareStrings, getCurrentLangObject, getDeepObjectValue } from "./data";

export default function getTranslate(data, currentLang = "en", textDB) {
  if (!data) return null;
  if (checkIsEmptyObjectOrArray(data)) return data;
  let newData = { ...data };
  
  if (!textDB) Object.entries(newData).forEach( ([key, val]) => (newData[key] = getCurrentLangObject(getDeepObjectValue(text, val), currentLang)) );
  else {
    Object.entries(newData).forEach( ([key, val]) => {
      let textToTranslate = textDB?.find(i => compareStrings(i?.key, val))?.text || getDeepObjectValue(text, val) || {}
      newData[key] = getCurrentLangObject(textToTranslate, currentLang)
    });
  }

  return newData;
}
