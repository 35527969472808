export default async function sendMessage(formData) {
  let sendURL = '/sendMessage.php';
  try {
    let response = await fetch(sendURL, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      return 'ok'; // Повертаємо 'OK', якщо запит успішний
    } else {
      console.log('Failed to send message');
      return 'error'; // Повертаємо 'error', якщо запит не успішний
    }
  } catch (error) {
    console.log('Error: ', error);
    return 'error'; // Повертаємо 'error' у разі винятку
  }
}
