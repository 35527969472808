import React from "react";

const SvgUse = ({ icontag, className, style }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className={className} style={style}>
      <use href={`${process.env.PUBLIC_URL}/assets/images/icons/icons.svg#${icontag}`} ></use>
    </svg>
  );
};

export default SvgUse;
