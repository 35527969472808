const { getProjects } = require('./getProjects');
const { getProjectById } = require('./getProjectById');
const { getLastProjects } = require('./getLastProjects');
const { getCategories } = require('./getCategories');

module.exports = {
  getProjects,
  getLastProjects,
  getCategories,
  getProjectById,
};
