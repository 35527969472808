export const text = {
  contacts: {
    en: "Contacts",
    ua: "Контакти",
  },
  address: {
    en: "Address",
    ua: "Адреса",
  },
  before: {
    en: "Before",
    ua: "Передній",
  },
  next: {
    en: "Next",
    ua: "Наступний",
  },
  review: {
    en: "Review",
    ua: "Відгук",
  },
  view_cases: {
    en: "View cases",
    ua: "Переглянути кейси",
  },
  get_started: {
    en: "Get Started",
    ua: "Обговорити проект",
  },
  form_title: {
    en: "Application form",
    ua: "Форма заявки",
  },
  name: {
    en: "Name",
    ua: "Im’я",
  },
  email: {
    en: "Email",
    ua: "Емаіл",
  },
  phone: {
    en: "Phone",
    ua: "Телефон",
  },
  services: {
    en: 'Services',
    ua: 'Напрямки',
  },
  send: {
    en: 'Send',
    ua: 'Відправити',
  },
  view_in_pdf: {
    en: 'View in pdf',
    ua: 'Переглянути в pdf',
  },
  nav: {
    en: [
      { title: "Homepage", path: "/", },
      { title: "Services", path: "#services", },
      { title: "Portfolio", path: "/projects", },
    ],
    ua: [
      { title: "Головна", path: "/", },
      { title: "Послуги", path: "#services", },
      { title: "Портфоліо", path: "/projects", },
    ],
  },
  notifications: {
    success: {
      en: 'The request was sent successfully',
      ua: 'Заявку успішно відправлено',
    },
    error: {
      en: 'Error 404',
      ua: 'Виникла помилка 404',
    },
  },
  pages: {
    home: {
      views: {
        hero: {
          title: {
            en: 'Tailored Web & App Solutions for Businesses of Any Scale',
            ua: 'Індивідуальні веб  рішення для бізнесу будь-якого масштабу',
          },
          subtitle: {
            en: 'We are reliable partners for entrepreneurs who want to stay resilient in a fast-paced environment and quickly adapt to changes. We help them turn challenges into opportunities.',
            ua: 'Ми стаємо надійними партнерами підприємців для впевненого зростання та адаптації до змін, перетворюючи виклики на можливості.',
          },
          subtitle2: {
            en: 'Our goal is to be a partner who deeply understands your goals and helps you navigate in a fast-paced environment on your way to achieving them.',
            ua: '',
          },
          work_with_us: {
            en: 'Work with us',
            ua: 'Працюйте з нами',
          },
        },
        benefits: {
          title: {
            en: 'Benefits',
            ua: 'Переваги',
          },
          benefits_list: {
            en: [
              {
                title: 'Brand in the spotlight',
                text: 'A human-centered process of design development that blends business goals and user needs with design thinking in mind.',
              },
              {
                title: 'Tailored solutions',
                text: 'Complex analytical methods, combined with a data-driven approach, uncover essential insights',
              },
              {
                title: 'Cutting-edge technologies',
                text: 'Stay ahead in a fast-paced environment and achieve your goals faster',
              },
              {
                title: 'Partnership',
                text: 'Our partners value reliability and trustthese qualities are deeply embedded in our mindset and workflow',
              },
            ],
            ua: [
              {
                title: 'Творчий погляд',
                text: "Креативний дизайн що точно запам'ятається",
              },
              {
                title: 'Індивідуальний підхід',
                text: 'Детальне дослідження вашого бізнесу',
              },
              {
                title: 'Сучасні технології',
                text: 'Ваша впевненість в проeкті',
              },
              {
                title: 'Партнерство',
                text: 'Діловий підхід в комунікації та співпраці',
              },
            ],
          },
        },
        services: {
          title: {
            en: 'Services',
            ua: 'Послуги',
          },
          services_list: {
            en: [
              {
                title: "Website development",
                number: "01",
              },
              {
                title: "Web & Brand Design",
                number: "02",
              },
              {
                title: "Mobile application",
                number: "03",
              },
              {
                title: "WEB3",
                number: "04",
              },
            ],
            ua: [
              {
                title: "Розробка сайтів",
                number: "01",
              },
              {
                title: "Веб дизайн та брендинг",
                number: "02",
              },
              {
                title: "Мобільні додатки",
                number: "05",
              },
              {
                title: "WEB3",
                number: "04",
              },
            ],
          },
          // services_list: {
          //   en: [
          //     {
          //       title: "Website development",
          //       number: "01",
          //     },
          //     {
          //       title: "Telegram development",
          //       number: "02",
          //     },
          //     {
          //       title: "Web & Brand Design",
          //       number: "03",
          //     },
          //     {
          //       title: "WEB3",
          //       number: "04",
          //     },
          //     {
          //       title: "Mobile application",
          //       number: "05",
          //     },
          //   ],
          //   ua: [
          //     {
          //       title: "Розробка сайтів",
          //       number: "01",
          //     },
          //     {
          //       title: "Telegram боти та автоматизація",
          //       number: "02",
          //     },
          //     {
          //       title: "Веб дизайн та брендинг",
          //       number: "03",
          //     },
          //     {
          //       title: "WEB3",
          //       number: "04",
          //     },
          //     {
          //       title: "Мобільні додатки",
          //       number: "05",
          //     },
          //   ],
          // },
        },
        projects: {
          title: {
            en: 'Latest projects',
            ua: 'Останні проєкти',
          },
          all_projects_link: {
            en: 'All projects',
            ua: 'Всі проєкти',
          },
        },
        reviews: {
          title: {
            en: 'Reviews of our customers',
            ua: 'Відгуки наших клієнтів',
          },
          all_reviews_link: {
            en: 'View reviews',
            ua: 'Переглянути відгуки',
          },
        },
      },
    },
  },
};
// pages.home.views.hero.title
