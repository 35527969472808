export function isAvailableArray(arr) {
  if (!arr || !Array.isArray(arr)) return [];
  return arr;
}

export function isAvailableObject(obj) {
  if (!obj) return {};
  return obj;
}

export function isAvailableText(text, key, currentLang) {
  const res = isAvailableObject(text[key])[currentLang];

  if (!res) return key?.split("_")?.join(" ");

  return res;
}
