import React from 'react';
import { useLocation } from 'react-router-dom';
// components
import LinkBtn from 'components/LinkBtn/LinkBtn';
// scss
import './BackLink.scss';

const BackLink = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const backUrl = ["/projects", "/projects/", "/"]?.includes(from) ? from : "/projects"

  return (
    <>
      <LinkBtn
        type="link"
        className={'backLink link--arrow-left'}
        icontag={'arrow'}
        to={backUrl}
      />
    </>
  );
};

export default BackLink;
