import React from 'react';
// views
import Projects from 'views/Projects/Projects';

const ProjectsPage = () => {
  return (
    <>
      <Projects />
    </>
  );
};

export default ProjectsPage;
