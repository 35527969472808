import React, { useState } from 'react';
// hooks
import { useLang, useProjects } from 'hooks';
import { useWindowDimensions } from 'hooks';
// helpers
import { getCurrentLangObject, getTranslate } from 'helpers';
// components
import LinkBtn from 'components/LinkBtn/LinkBtn';
import ProjectSwiperDemo from 'components/ProjectSwiper/ProjectSwiperDemo';
// scss
import './ProjectCase.scss';
// ===== text translate START =====
const dataToTranslate = { textViewCases: "view_cases" }
// ===== text translate END =====

const ProjectCaseDemo = ({ data }) => {
  const { currentLang } = useLang();
  const { categories } = useProjects();
  const [ active, setActive ] = useState(false);
  const { id, category_id, project_url, tags, text, content } = data ?? {};
  const currentCategoryName = categories?.find(i => i.id === category_id);
  const currentCategoryIndex = categories?.findIndex(i => i.id === category_id);
  const { is1200 } = useWindowDimensions();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textViewCases } = translate ?? {};
  const textCategory = getCurrentLangObject(currentCategoryName, currentLang);
  const textText = getCurrentLangObject(text, currentLang);
  // ===== text translate END =====
  const contentSwiperData = content?.map(i => {
    return {
      type: i?.type,
      src: `${process.env.PUBLIC_URL}/assets/last-projects/${id}/${i.name}`,
      className: 'project-swiper-item',
    }
  })
 
  return (
    <div className={`project project--demo ${active ? "active" : ""}`}>
      <div className="project__container container">
        <div className="project__category project-category">
          <span className="project-category__number">{`#0${currentCategoryIndex + 1}`}</span>
          <span className="project-category__name">{textCategory}</span>
          <div className="project__btn--wrap">
            {is1200 
              ? <LinkBtn className={"project__link link--primary"} type="link" text={textViewCases} ariaLabel={"Open project page"} to={project_url} />
              : <LinkBtn className={"project__link link--eye"} type="link" icontag={'eye'} ariaLabel={"Open project page"} to={project_url} />
            }
            <LinkBtn className={"project__btn button--arrow-bottom-right"} type="button" icontag={'arrow'} ariaLabel={"Show case"} onClick={() => setActive(!active)} />
          </div>
        </div>
        <div className="project__tags project-tags">
          <ul className="project-tags__list">
            {tags?.map((i, idx) => ( <li key={idx} className="project-tags__item">{i}</li> ))}
          </ul>
        </div>
        <div className="project__content">
          <div className="project__content-inner">
            <p className="project__text">{textText}</p>
            <ProjectSwiperDemo swiperListData={contentSwiperData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCaseDemo;
