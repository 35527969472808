import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// hooks
import { useLang } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// scss
import './Nav.scss';
// ===== text translate START =====
const dataToTranslate = { textNav: "nav" }
// ===== text translate END =====

const Nav = ({ className = '', setIsBurgerMenuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentLang } = useLang();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textNav } = translate ?? {};
  // ===== text translate END =====

  const handleClick = (path) => {
    if (path?.startsWith("/")) {
      setIsBurgerMenuOpen();
      return navigate(path);
    }

    if (location.pathname === '/') {
      // Якщо вже на головній сторінці, просто прокручуємо до секції
      handleNavScroll(path);
    } else {
      // Якщо на іншій сторінці, спочатку переходимо на головну
      navigate('/');
      // Використовуємо таймер для затримки скролу після навігації
      setTimeout(() => handleNavScroll(path), 100); // Затримка 200 мс, щоб дочекатися навантаження сторінки
    }
  };

  const handleNavScroll = (path) => {
    const sectionId = path.replace('#', '');
    const section = document.getElementById(sectionId);
    if (section) {
      // Вимикаємо scroll-snap
      document.documentElement.style.scrollSnapType = "none";
  
      section.scrollIntoView({ behavior: 'smooth' });
  
      // Повертаємо scroll-snap після завершення скролу
      setTimeout(() => {
        document.documentElement.style.scrollSnapType = ""; // Заміна на початкове значення
      }, 1000); // Затримка на 1 сек для завершення скролу
    }
  
    setIsBurgerMenuOpen();
  };

  return (
    <nav className={`${className} nav`}>
      <ul className="nav__list">
        {textNav?.map((i, idx) => (
          <li key={idx} className="nav__item">
            <NavLink className="nav__link" to={i?.path} data-idx={idx + 1} onClick={(e) => {e.preventDefault(); handleClick(i?.path)}} >{i?.title}</NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
