import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// hooks
import { useLang, useProjects } from 'hooks';
// helpers
import { getCurrentLangObject, getTranslate, isAvailableArray } from 'helpers';
// components
import Review from 'components/Review/Review';
import LinkBtn from 'components/LinkBtn/LinkBtn';
import BackLink from 'components/BackLink/BackLink';
import ProjectSwiperGallery from 'components/ProjectSwiper/ProjectSwiperGallery';
// scss
import './ProjectCase.scss';
// ===== text translate START =====
const dataToTranslate = {
  textBefore: "before",
  textNext: "next",
  textReview: "review",
  textViewInPdf: "view_in_pdf",
}
// ===== text translate END =====

const ProjectCase = ({ data, projects }) => {
  const location = useLocation();
  const { currentLang } = useLang();
  const { categories } = useProjects();
  const { id, categories_ids, title, link, tags, text, text2, content, review, pdf } = data ?? {};
  // const currentCategoryName = categories?.find(i => i.id === category_id);
  // const currentCategoryIndex = categories?.findIndex(i => i.id === category_id);
  const currentCategoryIndex = categories?.findIndex(i => i.id === isAvailableArray(categories_ids)[0]);
  const currentCategoryNames = categories?.filter(i => categories_ids?.includes(i.id));
  // ===== text translate START =====
  const textTitle = getCurrentLangObject(title, currentLang);
  const textText = getCurrentLangObject(text, currentLang);
  const textText2 = getCurrentLangObject(text2, currentLang);
  // const textCategory = getCurrentLangObject(currentCategoryName, currentLang);
  const textCategories = currentCategoryNames?.map(i => getCurrentLangObject(i, currentLang))?.join(", ")
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textBefore, textNext, textReview, textViewInPdf } = translate ?? {};
  // ===== text translate END =====
  const pdfUrl = `https://2bot.agency/assets/pdf/${pdf}_${currentLang}.pdf`
  const contentSwiperData = content?.map(i => {
    return {
      type: i?.type,
      src: `${process.env.PUBLIC_URL}/assets/projects/${id}/${currentLang}/${i.name}`,
      className: 'project-swiper-item',
    }
  })
  // Знайдемо індекс поточного проекту
  const currentIndex = projects?.findIndex((project) => project.id === id);
  // Отримаємо ID попереднього та наступного проектів
  const prevProjectId = currentIndex > 0 ? projects[currentIndex - 1].id : null;
  const nextProjectId = currentIndex < projects?.length - 1 ? projects[currentIndex + 1].id : null;

  return (
    <section className="project-page__section">
      <div className="project-page__container container">
        <BackLink />
        <h1 className="project-page__title">{textTitle}</h1>
        {link && <LinkBtn to={link || "#"} className={'project-page__link link--arrow-bottom-right'} type="link" icontag={'arrow-top-right'} target={"_blank"} />}
      </div>
      <div className="project project--page">
        <div className="project__container container">
          <div className="project__category project-category">
            <span className="project-category__number">{`#0${currentCategoryIndex + 1}`}</span>
            <span className="project-category__name">{textCategories}</span>
          </div>
          <div className="project__tags project-tags">
            <ul className="project-tags__list">
              {tags?.map((i, idx) => ( <li key={idx} className="project-tags__item"> {i} </li> ))}
            </ul>
          </div>
          <div className="project__desc">
            <p className="project__text">{textText}</p>
          </div>
          <ProjectSwiperGallery swiperListData={contentSwiperData} />
          <Link className='project__link--pdf' to={pdfUrl} target='_blank'>{textViewInPdf}</Link>
          <div className="project__desc">
            <p className="project__text">{textText2}</p>
          </div>
          <div className="project__review-wrap">
            <h2 className="project__review-title">{textReview}</h2>
            <Review className="project__review" data={{ id, ...review}} />
          </div>
        </div>
      </div>
      <div className="project-page__nav projects-nav container">
        <Link className='projects-nav__link projects-nav__link--prev' state={{ from: location }} to={prevProjectId ? `/projects/${prevProjectId}` : '#'} aria-disabled={!prevProjectId}>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M5.78002 8.46529L8.26708 6.00459C8.47564 5.79823 8.47622 5.46308 8.26837 5.25601C8.06052 5.04894 7.72295 5.04835 7.51438 5.25471L4.11563 8.61745C3.90706 8.82381 3.90648 9.15896 4.11433 9.36603L7.50147 12.7405C7.70932 12.9476 8.04689 12.9481 8.25546 12.7418C8.46402 12.5354 8.4646 12.2003 8.25675 11.9932L5.7782 9.52394L13.9811 9.53808L13.983 8.47944L5.78002 8.46529Z" />
            </svg>
          </span>
         {textBefore}
        </Link>
        <Link className='projects-nav__link projects-nav__link--next' state={{ from: location }} to={nextProjectId ? `/projects/${nextProjectId}` : '#'} aria-disabled={!nextProjectId}> 
          {textNext}
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M12.2208 9.52935L9.73796 11.9943C9.52975 12.201 9.52975 12.5362 9.73796 12.7429C9.94617 12.9496 10.2837 12.9496 10.492 12.7429L13.8849 9.37431C14.0931 9.1676 14.0931 8.83245 13.8849 8.62573L10.492 5.25713C10.2837 5.05042 9.94617 5.05042 9.73796 5.25713C9.52975 5.46385 9.52975 5.799 9.73796 6.00571L12.2208 8.4707L4.01782 8.4707L4.01782 9.52935L12.2208 9.52935Z" />
            </svg>
          </span>
        </Link>
      </div>
    </section>
  );
};

export default ProjectCase;
