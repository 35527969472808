import { createAsyncThunk } from '@reduxjs/toolkit';
import { setErrorRes } from 'helpers';

export const getProjectById = createAsyncThunk(
  'projects/getProjectById',
  async (projectId, thunkAPI) => {
    try {
      const response = await fetch('/data/projects.json'); // Отримуємо всі проекти
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response?.json();
      // Знаходимо проект за ID
      const project = data?.find(i => i?.id === projectId);
      if (!project) {
        throw new Error('Project not found');
      }
      return project; // Повертаємо проект
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
