import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
// // scss
import './Modal.scss';

const htmlRef = document.querySelector('html');
const modalRoot = document.querySelector('#modal-root');

const Modal = ({ showModal, setShowModal, className = '', children }) => {

  function onEscKeyPress(e) {
    if (e.code === 'Escape') setShowModal(false);
  }
  function onBackdropClick(e) {
    if (e.currentTarget === e.target) setShowModal(false);
  }

  useEffect(() => {
    if (showModal) {
      htmlRef?.classList.add('modalIsOpen');
      window.addEventListener('keydown', onEscKeyPress);
      document.getElementById("modalBackdrop").addEventListener('click', onBackdropClick);
    }
    else {
      htmlRef?.classList.remove('modalIsOpen');
      window.removeEventListener('keydown', onEscKeyPress);
      document.getElementById("modalBackdrop").removeEventListener('click', onBackdropClick);
    }
  // eslint-disable-next-line 
  }, [showModal]);

  return createPortal(
    <div className={`modal ${showModal ? '' : 'isHidden'} ${className}`} aria-hidden={!showModal}>
      <div className="modal__backdrop backdrop" id='modalBackdrop'>
        <div className="modal__container container">
          {children}
        </div>
      </div>
    </div>,
    modalRoot
  );
};

export default Modal;
