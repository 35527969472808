import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
// hooks
import { useLang, useProjects } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// components
import ProjectCaseDemo from 'components/ProjectCase/ProjectCaseDemo';
// scss
import './Portfolio.scss';
// ===== text translate START =====
const dataToTranslate = {
  textTitle: "pages.home.views.projects.title",
  textAllProjectsLink: "pages.home.views.projects.all_projects_link",
}
// ===== text translate END =====

const Portfolio = forwardRef((props, ref) => {
  const { currentLang } = useLang();
  const { lastProjects } = useProjects();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textTitle, textAllProjectsLink } = translate ?? {};
  // ===== text translate END =====

  return (
    <section ref={ref} className="portfolio scroll-snap-section" id="portfolio">
      <div className="portfolio__container container">
        <div className="portfolio__title-wrap">
          <h2 className="portfolio__title">{textTitle}</h2>
          <NavLink className="portfolio__link" to="/projects"> {textAllProjectsLink} </NavLink>
        </div>
      </div>
      <ul className="portfolio__list">
        {lastProjects?.map((i, idx) => (
          <li key={idx} className="portfolio__item">
            <ProjectCaseDemo data={i} idx={idx} />
          </li>
        ))}
      </ul>
    </section>
  );
});

export default Portfolio;
