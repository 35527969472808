import React from 'react';
// hooks
import { useLang } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// scss
import './OrderBtn.scss';
// ===== text translate START =====
const dataToTranslate = { textGetStarted: "get_started", }
// ===== text translate END =====

const OrderBtn = ({ setShowModal, isVisible }) => {
  const { currentLang } = useLang();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textGetStarted, } = translate ?? {};
  // ===== text translate END =====
  
  return (
    <div className='order-btn__wrap container'>
      <button className={`order-btn button button--primary ${isVisible ? "isVisible" : ""}`} aria-label='Order service' type='button' onClick={() => setShowModal(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="38" viewBox="0 0 35 38" fill="none">
          <path d="M3.25213 25.8413L11.6621 33.3005M3.25213 25.8413L2.08311 32.8645C1.82083 34.4402 3.29567 35.7483 4.82876 35.2997L11.6621 33.3005M3.25213 25.8413L20.3854 6.52418M11.6621 33.3005L28.7954 13.9834M20.3854 6.52418L23.8459 2.62266C24.6399 1.72743 26.0093 1.64539 26.9045 2.43941L32.0726 7.02324C32.9678 7.81726 33.0499 9.18666 32.2559 10.0819L28.7954 13.9834M20.3854 6.52418L28.7954 13.9834" strokeWidth="3.25"/>
        </svg>
        <span>{textGetStarted}</span>
      </button>
    </div>
  )
  
};

export default OrderBtn;
