import React from 'react';
// hooks
import { useProjects } from 'hooks';
// components
import ProjectsCategoryItem from 'components/ProjectsCategoryItem/ProjectsCategoryItem';
// scss
import './Projects.scss';

const Projects = () => {
  const { projects, categories } = useProjects();
  // Фільтруємо categories та додаємо відповідні проекти
  const filteredCategoriesWithProjects = categories
    ?.filter(category =>
      projects?.some(project => project?.categories_ids?.includes(category?.id))
      // projects?.some(project => project?.category_id === category?.id)
    )
    ?.map(category => ({
      ...category, // Зберігаємо властивості категорії
      projects: projects?.filter(project => project?.categories_ids?.includes(category?.id)), // Додаємо проекти для цієї категорії
      // projects: projects?.filter(project => project?.category_id === category?.id), // Додаємо проекти для цієї категорії
    }));

  return (
    <section className="projects">
      <div className="projects__container container">
        <ul className="projects-category__list">
          {filteredCategoriesWithProjects?.map((i, idx) => (
            <ProjectsCategoryItem key={idx} data={i} idx={idx} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Projects;
