import React, { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// redux
import { getCategories, getLastProjects, getProjects } from 'redux/projects/operations';
// hooks
import { useLang } from 'hooks';
// components
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import LoaderRive from 'components/Loader/LoaderRive';

const PublicLayout = ({ isHomePage }) => {
  const [render, setRender] = useState(true);
  const [timeStart, setTimeStart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingOpacity, setIsLoadingOpacity] = useState(false);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const { currentLangAttribute } = useLang();
  const dispatch = useDispatch();
  // ===== document =====
  const htmlRef = document.querySelector('html');
  htmlRef.setAttribute('lang', currentLangAttribute);
  isHomePage 
    ? htmlRef.classList.add('scrollSnap')
    : htmlRef.classList.remove('scrollSnap')
  isLoading
    ? htmlRef.classList.add('freeze')
    : htmlRef.classList.remove('freeze');
  isBurgerMenuOpen
    ? htmlRef.classList.add('burgerMenuIsOpen')
    : htmlRef.classList.remove('burgerMenuIsOpen');
  // ===== document =====

  // // Ефект для симуляції завантаження програми
  useEffect(() => {
    if (!timeStart) return setTimeStart(true);
    console.log("timeStart return")
    // Таймер для зміни стану isLoadingOpacity через 8 секунд
    const timer1 = setTimeout(() => { setIsLoadingOpacity(true); }, 8000);
    // Таймер для зміни стану isLoading через 10 секунд
    const timer2 = setTimeout(() => { setIsLoading(false); }, 8500);
    // Очищуємо обидва таймери при відміненні компонента
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [timeStart]);

  useEffect(() => {
    if (render) return setRender(false);
    dispatch(getCategories());
    dispatch(getLastProjects());
    dispatch(getProjects());
  }, [render, dispatch]);

  return (
    <>
      <Header
        isBurgerMenuOpen={isBurgerMenuOpen}
        setIsBurgerMenuOpen={setIsBurgerMenuOpen}
        className={isHomePage ? "scrollSnap" : ""}
      />
      <main>
        <Suspense fallback={<div className='loader--rive'></div>}>
          <Outlet />
        </Suspense>
      </main>
      <Footer />
      {isLoading && ( <LoaderRive className={isLoadingOpacity ? 'isHidden' : ''} /> )}
    </>
  );
};

export default PublicLayout;
