import React from 'react';
// components
import Marquee from 'components/Marquee/Marquee';
import Contacts from 'components/Contacts/Contacts';
// scss
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer scroll-snap-section">
      <div className="footer__container container">
        <div className="footer__top">
          <Contacts className={"footer__contacts"}/>
        </div>
        <div className="footer__bottom">
          <p className="copyright">
            <span>@ 2024 2bot agency.</span> All right reserved.
          </p>
        </div>
      </div>
      <video 
        className='footer__video video--bg' 
        preload="none"
        width="600" 
        height="400" 
        loop 
        muted 
        autoPlay
        playsInline
        >
        <source type="video/mp4" src="/assets/video/2091284813-left.mp4" />
      </video>
      <Marquee />
    </footer>
  );
};

export default Footer;
