import React, { useState } from 'react';
// hooks
import { useLang } from 'hooks';
// helpers
import { compareStrings } from 'helpers';

// scss
import './InputDropdown.scss';

const InputDropdown = ({ showListInit, options, setOptions, checkedOptions }) => {
  const [showList, setShowList] = useState(showListInit || false);
  const { currentLang } = useLang();

  function onChange(e) {
    const newOptions = [...options]?.map(i => {
      if (compareStrings(i[currentLang], e.target.value)) return { ...i, checked: !i.checked };
      return i;
    });

    setOptions(newOptions);
  }
 
  return (
    <div className={`input-dropdown ${showList ? "active" : ""}`} onClick={() => setShowList(!showList) }>
      <div className="input-dropdown__checked dropdown-checked">
        <ul className="dropdown-checked__list">
          {checkedOptions?.map((i, idx) => (
            <li key={idx} className="dropdown-checked__item">{i[currentLang]}</li>
          ))}
        </ul>
        <button className='input-dropdown__btn button' aria-label={`${showList ? "Hide" : "Show"} list`} type='button' onClick={() => setShowList(!showList)}> </button>
      </div>
      <div className={`input-dropdown__options dropdown-options ${showList ? "active" : ""}`}>
        <div className="dropdown-options__inner">
          <ul className="dropdown-options__list">
            {options?.map((i, idx) => (
              <li key={idx} className="dropdown-options__item">
                <input className='input--none' type="checkbox" id={`option-${idx}`} value={i[currentLang]} checked={i.checked} onChange={onChange} />
                <label className='label--checkbox' htmlFor={`option-${idx}`}> {i[currentLang]} </label>
              </li>
            ))}
        </ul>
        </div>
      </div>
    </div>
  );
};

export default InputDropdown;
