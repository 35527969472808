import React from 'react';
import { Link } from 'react-router-dom';
// scss
import './Logo.scss';

const Logo = ({ className }) => {
  return (
    <Link className={`logo ${className}`} to="/" aria-label="Go to homepage">
      <svg width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.6817 10.0591C32.6817 10.8637 32.0295 11.5152 31.2256 11.5152C30.4217 11.5152 29.7695 10.863 29.7695 10.0591C29.7695 9.2552 30.4217 8.60303 31.2256 8.60303C32.0295 8.60303 32.6817 9.2552 32.6817 10.0591Z" fill="white"/>
        <path d="M45.8858 6.64308C45.8858 7.44765 45.2336 8.09914 44.4297 8.09914C43.6258 8.09914 42.9736 7.44697 42.9736 6.64308C42.9736 5.83919 43.6258 5.18701 44.4297 5.18701C45.2336 5.18701 45.8858 5.83919 45.8858 6.64308Z" fill="white"/>
        <path d="M10.1878 16.5292C10.1878 17.3331 9.53425 17.9839 8.73036 17.9839H1.03334C0.914576 17.9839 0.816406 17.8857 0.816406 17.8075V15.4473L6.34341 7.8409C6.71961 7.32602 6.87819 6.85165 6.87819 6.43495C6.87819 5.62213 6.26515 5.04685 5.49009 5.04685C4.71503 5.04685 3.85279 5.65989 3.80954 6.77614C3.81229 6.78781 3.81229 6.80223 3.81229 6.8139C3.81229 7.59788 3.1759 8.23083 2.39192 8.23083C1.60794 8.23083 1.00382 7.62671 0.977733 6.86882C0.974987 6.8544 0.974987 6.83724 0.974987 6.82282V6.79948C1.00176 4.2917 2.40702 2.13403 5.49009 2.13403C7.80977 2.13403 9.98803 3.85783 9.98803 6.27568C9.98803 7.20726 9.65233 8.27682 8.86011 9.30657L4.50153 15.0711H8.73036C9.53425 15.0711 10.1878 15.7247 10.1878 16.5285" fill="white"/>
        <path d="M20.6767 10.0295C23.1928 10.8814 23.0939 13.3377 23.0939 13.3377C23.0939 17.756 18.5767 17.9537 17.5072 17.9537H16.5955C15.7964 17.9537 15.1491 17.3064 15.1491 16.5073C15.1491 15.7082 15.7964 15.0608 16.5955 15.0608H17.5072C18.4978 15.0608 20.063 14.7835 20.063 13.2581C20.063 11.3956 17.5072 11.5144 17.5072 11.5144H12.1182V2.28302C12.1182 2.20338 12.217 2.10453 12.3358 2.10453H17.5065C17.5065 2.10453 22.8756 1.98576 22.8756 6.6416C22.8756 8.48416 21.885 9.53382 20.6761 10.0295M17.5072 4.93771H15.1298V8.56311H17.5072C17.5072 8.56311 19.8447 8.56311 19.8447 6.82009C19.8447 4.9178 17.5072 4.9384 17.5072 4.9384" fill="white"/>
        <path d="M31.2944 2.10449C26.1635 2.10449 23.6475 6.06765 23.6475 10.0095C23.6475 13.9514 26.1635 17.9544 31.2951 17.9544C36.4266 17.9544 38.9015 13.9713 38.9015 10.0095C38.9015 6.04774 36.3477 2.10449 31.2944 2.10449ZM31.2944 15.1809C28.1852 15.1809 26.6378 12.6244 26.6378 10.0672C26.6378 7.50998 28.1852 4.89854 31.2944 4.89854C34.4035 4.89854 35.931 7.47291 35.931 10.0672C35.931 12.6615 34.3665 15.1809 31.2944 15.1809Z" fill="white"/>
        <path d="M39.6543 3.4617C39.6543 2.71204 40.2618 2.10449 41.0115 2.10449H47.8264C48.576 2.10449 49.1836 2.71204 49.1836 3.4617C49.1836 4.21136 48.576 4.81891 47.8264 4.81891H41.0115C40.2618 4.81891 39.6543 4.21136 39.6543 3.4617Z" fill="white"/>
        <path d="M14.7529 16.4976C14.7529 17.3021 14.1008 17.9536 13.2969 17.9536C12.493 17.9536 11.8408 17.3015 11.8408 16.4976C11.8408 15.6937 12.493 15.0415 13.2969 15.0415C14.1008 15.0415 14.7529 15.6937 14.7529 16.4976Z" fill="white"/>
        <path d="M44.4297 17.9462C43.6251 17.9462 42.9736 17.294 42.9736 16.4901V9.84962C42.9736 9.04504 43.6258 8.39355 44.4297 8.39355C45.2336 8.39355 45.8858 9.04573 45.8858 9.84962V16.4901C45.8858 17.2947 45.2336 17.9462 44.4297 17.9462Z" fill="white"/>
        <path d="M0.805664 27.763L4.20177 20.1182H5.94891L9.37797 27.763H7.52168L4.71527 21.014H5.42511L2.61871 27.763H0.805664ZM2.52054 26.125L2.96814 24.7815H6.92168L7.38026 26.125H2.52054Z" fill="white"/>
        <path d="M13.7578 27.8942C13.1537 27.8942 12.5962 27.7974 12.0869 27.6045C11.5775 27.4116 11.1367 27.137 10.7653 26.78C10.3939 26.423 10.1049 26.0043 9.89692 25.5244C9.6896 25.0439 9.58594 24.5159 9.58594 23.9407C9.58594 23.3654 9.6896 22.8375 9.89692 22.3569C10.1042 21.8764 10.3974 21.4576 10.7763 21.1013C11.1546 20.7443 11.5988 20.4697 12.1088 20.2768C12.6182 20.0839 13.1791 19.9878 13.7907 19.9878C14.4676 19.9878 15.0772 20.1011 15.6203 20.3262C16.1626 20.5521 16.6198 20.8796 16.9905 21.3093L15.866 22.3576C15.5818 22.059 15.2763 21.8393 14.9489 21.6972C14.6214 21.5551 14.261 21.4844 13.8676 21.4844C13.5038 21.4844 13.1688 21.5448 12.8626 21.6642C12.5571 21.7844 12.2928 21.9539 12.0711 22.1722C11.8486 22.3905 11.6777 22.6493 11.5576 22.948C11.4374 23.2466 11.3777 23.5775 11.3777 23.942C11.3777 24.3066 11.4374 24.6175 11.5576 24.9196C11.6777 25.2217 11.8486 25.4818 12.0711 25.7008C12.2928 25.9191 12.555 26.0901 12.8571 26.2143C13.1592 26.3386 13.4921 26.3997 13.8566 26.3997C14.2212 26.3997 14.5466 26.3413 14.8775 26.2246C15.209 26.1086 15.531 25.915 15.8441 25.6459L16.8491 26.9235C16.4269 27.2441 15.9429 27.4857 15.3965 27.6498C14.8507 27.8139 14.3042 27.8956 13.7585 27.8956M15.2317 26.6928V23.8205H16.8477V26.9221L15.2317 26.6928Z" fill="white"/>
        <path d="M20.2445 26.3434H24.4053V27.7631H18.4863V20.1189H24.2632V21.5386H20.2445V26.3434ZM20.1133 23.1875H23.7937V24.5743H20.1133V23.1875Z" fill="white"/>
        <path d="M25.8145 27.7631V20.1189H27.2774L31.7877 25.6226H31.0778V20.1189H32.825V27.7631H31.362L26.8518 22.2594H27.5616V27.7631H25.8145Z" fill="white"/>
        <path d="M38.2961 27.8942C37.7064 27.8942 37.1586 27.7974 36.6527 27.6045C36.1467 27.4116 35.708 27.137 35.3366 26.78C34.9652 26.423 34.6762 26.0043 34.4682 25.5244C34.2609 25.0439 34.1572 24.5159 34.1572 23.9407C34.1572 23.3654 34.2609 22.8375 34.4682 22.3569C34.6755 21.8764 34.9666 21.4576 35.3421 21.1013C35.717 20.7443 36.1577 20.4697 36.6636 20.2768C37.1696 20.0839 37.7174 19.9878 38.3071 19.9878C38.9696 19.9878 39.5703 20.1024 40.1092 20.3317C40.6481 20.561 41.0991 20.8981 41.4636 21.3423L40.3172 22.3905C40.0625 22.0919 39.7728 21.8661 39.4488 21.7137C39.1247 21.5606 38.7732 21.4844 38.395 21.4844C38.038 21.4844 37.7105 21.5448 37.4119 21.6642C37.1133 21.7844 36.8552 21.9539 36.6362 22.1722C36.4179 22.3905 36.2483 22.6493 36.1282 22.948C36.008 23.2466 35.9483 23.5775 35.9483 23.942C35.9483 24.3066 36.0087 24.6374 36.1282 24.9354C36.2483 25.234 36.4179 25.4928 36.6362 25.7111C36.8545 25.9294 37.1133 26.099 37.4119 26.2191C37.7105 26.3393 38.038 26.399 38.395 26.399C38.7732 26.399 39.1247 26.3207 39.4488 26.1642C39.7728 26.0077 40.0618 25.7805 40.3172 25.4818L41.4636 26.5301C41.0991 26.9743 40.6481 27.3127 40.1092 27.5455C39.5703 27.7782 38.9662 27.8949 38.2961 27.8949" fill="white"/>
        <path d="M44.4666 27.7631V24.6402L44.8702 25.7104L41.4961 20.1189H43.3853L45.9625 24.3999H44.8812L47.4693 20.1189H49.1952L45.8423 25.7104L46.2467 24.6402V27.7631H44.4666Z" fill="white"/>
      </svg>
    </Link>
  );
};

export default Logo;
