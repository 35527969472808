import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { langReducer } from './lang/langSlice';
import { projectsReducer } from './projects/projectsSlice';

const langPersistConfig = {
  key: 'lang',
  storage,
  whitelist: ['lang'],
};

export const store = configureStore({
  reducer: {
    lang: persistReducer(langPersistConfig, langReducer),
    projects: projectsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
