import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// hooks
import { useLang } from 'hooks';
// helpers
import { getCurrentLangObject } from 'helpers';
// components
import SvgUse from 'components/SvgUse/SvgUse';
// scss
import './ProjectsCategoryItem.scss';

const ProjectsCategoryItem = ({ data, idx }) => {
  const [active, setActive] = useState(false);
  const { currentLang } = useLang();
  const location = useLocation();
  const textCategory = getCurrentLangObject(data, currentLang);
  const projects = data?.projects;

  return (
    <li key={idx} className={`projects-category__item ${active ? 'active' : ''}`} >
      <button className="projects-category__btn" type="button" onClick={() => setActive(!active)} >
        <span className="project-category__number">{`#0${idx + 1}`}</span>
        <span className="project-category__name">{textCategory}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none" >
          <path fillRule="evenodd" clipRule="evenodd" d="M6.35845 7.94353C5.99189 7.59597 5.39758 7.59597 5.03102 7.94353C4.66446 8.29109 4.66446 8.85459 5.03102 9.20215L11.0045 14.866C11.371 15.2135 11.9653 15.2135 12.3319 14.866L18.3053 9.20215C18.6719 8.8546 18.6719 8.29109 18.3053 7.94353C17.9388 7.59597 17.3444 7.59597 16.9779 7.94353L11.6682 12.9181L6.35845 7.94353Z" fill="white" />
        </svg>
      </button>
      <div className="projects-category__content">
        <div className="projects-category__content-inner">
          <ul className="projects__list">
            {projects?.map((i, idx) => {
              const title = getCurrentLangObject(i?.title, currentLang);
              const url = `/projects/${i?.id}`;
              const logoSrc = `${process.env.PUBLIC_URL}/assets/projects/${i?.id}/${i?.logo}`;

              return (
                <li key={idx} className="projects__item">
                  <NavLink className="projects__item-link" to={url} state={{ from: location }}>
                    {i?.logo
                      ? <img loading='lazy' className='projects__item-logo' src={logoSrc} alt={`Logo ${i?.id}` } width={50} height={50}/>
                      : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none"> <circle cx="8.99384" cy="9.78223" r="9" fill="#D9D9D9"/> </svg>
                    }
                    {title}
                    <div className='projects__link link--arrow-right'>
                      <SvgUse icontag={"arrow"} />
                    </div>
                  </NavLink>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </li>
  );
};

export default ProjectsCategoryItem;