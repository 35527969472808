import React, { forwardRef, useEffect, useRef, useState } from 'react';
// hooks
import { useLang } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// scss
import './Benefits.scss';
// ===== text translate START =====
const dataToTranslate = {
  textTitle: "pages.home.views.benefits.title",
  textBenefitsList: "pages.home.views.benefits.benefits_list",
}
// ===== text translate END =====

const Benefits = forwardRef((props, ref) => {
  const benefitRef = useRef(null);
  const [start, setStart] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [firstCircle, setFirstCircle] = useState(true);
  const [fromBenefit4, setFromBenefit4] = useState(false);
  const [fromBenefit42, setFromBenefit42] = useState(false);
  const { currentLang } = useLang();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textTitle, textBenefitsList } = translate ?? {};
  // ===== text translate END =====
  function generateClassName() {
    const basicClassName = "benefit";
    if (!start) return basicClassName;
    const newClassName = (`${basicClassName} benefit--${currentIndex + 1} ${ start ? 'start' : '' } ${ fromBenefit4 ? 'from-benefit-4' : '' } ${ fromBenefit42 ? 'from-benefit-42' : '' } ${firstCircle ? "firstCircle" : ""}`).trim();
    return newClassName
  }

  // Запуск і зупинка анімації при видимості/невидимості
  useEffect(() => {
    const currentRef = benefitRef.current;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setStart(true); // Запускаємо анімацію
          } else {
            setStart(false); // Зупиняємо анімацію
            setCurrentIndex(0); // Повертаємо індекс до початку
          }
        });
      },
      { threshold: 0.6 } // 50% елементу має бути видно на екрані
    );

    if (currentRef)  observer.observe(currentRef);
    return () => { if (currentRef) observer.unobserve(currentRef); };
  }, []);

  useEffect(() => {
    if (!start) return; // Не запускати, якщо анімація ще не повинна початись
    const nextTimeout = setTimeout(() => {
      setCurrentIndex(prevIndex => {
        if (prevIndex === 3) setFromBenefit4(true);
        if (prevIndex === 0) setFromBenefit4(false);
        if (prevIndex === 0) setFirstCircle(false);
        return (prevIndex + 1) % 4
        // return (prevIndex + 1) % benefitsData?.length
      })
    }, 4000);

    const nextTimeout2 = setTimeout(() => {
      if (currentIndex === 0) setFromBenefit42(true);
      if (currentIndex === 1) setFromBenefit42(false);
    }, 500);

    return () => { 
      clearTimeout(nextTimeout); 
      clearTimeout(nextTimeout2); 
    };
  }, [start, currentIndex]);

  return (
    <section ref={ref} className="benefits scroll-snap-section">
      <div className="benefits__container container">
        <h2 className="benefits__title">{textTitle}</h2>
        <div ref={benefitRef} className={generateClassName()}>
          <span className="benefit__toggle"></span>
          <h3 className="benefit__title">{textBenefitsList[currentIndex]?.title}</h3>
          <p className="benefit__text">{textBenefitsList[currentIndex]?.text}</p>
        </div>
      </div>
    </section>
  );
});

export default Benefits;
