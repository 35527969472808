import React from 'react';
// scss
import './BurgerMenuBtn.scss';

const BurgerMenuBtn = ({ isBurgerMenuOpen, setIsBurgerMenuOpen }) => {
  return (
    <button
      className="burger-menu__btn"
      type="button"
      aria-label="Open burger menu"
      aria-controls="burger-menu"
      aria-expanded={isBurgerMenuOpen}
      onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
    >
      <span className="burger-line"></span>
      <span className="burger-line"></span>
    </button>
  );
};

export default BurgerMenuBtn;
