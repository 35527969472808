import React from 'react';

const ProjectSwiperItem = ({ data }) => {
  const {
    type,
    src,
    alt = 'Name',
    width = '100%',
    height = null,
    className = '',
  } = data ?? {};

  return (
    <a href={src} data-pswp-width="1600" data-pswp-height="900">
      {type === 'image' ? (
        <img
          className={className}
          src={src}
          width={width}
          height={height}
          alt={alt}
          loading="lazy"
        />
      ) : (
        <video
          className={className}
          src={src}
          width={width}
          height={height}
          preload="none"
          loop
          autoPlay
          muted
        >
          <source type="video/mp4" src={src} />
        </video>
      )}
    </a>
  );
};

export default ProjectSwiperItem;
