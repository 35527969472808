import React, { forwardRef } from 'react';
// hooks
import { useLang, useProjects } from 'hooks';
// helpers
import { getCurrentLangObject, getTranslate } from 'helpers';
// scss
import './Services.scss';
// ===== text translate START =====
const dataToTranslate = { textTitle: "pages.home.views.services.title", }
// ===== text translate END =====

const Services = forwardRef((props, ref) => {
  const { currentLang } = useLang();
  const { categories } = useProjects();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textTitle, } = translate ?? {};
  const textServicesList = categories?.map(i => getCurrentLangObject(i, currentLang));
  // ===== text translate END =====

  return (
    <section ref={ref} className="services scroll-snap-section" id="services">
      <div className="services__container container">
        <h2 className="services__title">{textTitle}</h2>
        <ul className="services__list">
          {textServicesList?.map((i, idx) => (
            <li key={idx} className="services__item service" data-number={`0${idx + 1}`}>{i}
              <img loading='lazy' className="service__img" src={`${process.env.PUBLIC_URL}/assets/images/service-${idx + 1}.webp`} alt={`${i.title}`} width={342} height={188}/>
            </li>
          ))}
        </ul>
      </div>
      <video 
        className='services__video video--bg' 
        preload="none" 
        width="600" 
        loop 
        muted 
        autoPlay
        playsInline
        type='video/mp4'
      >
        <source type="video/mp4" src="/assets/video/1478102380.mp4" />
      </video>
    </section>
  );
});

export default Services;
