import React from 'react';
import { useRive } from '@rive-app/react-canvas';

import { createPortal } from 'react-dom';
// scss
import './Loader.scss';

const modalRoot = document.querySelector('#modal-root');

const LoaderRive = ({ className }) => {
  const { RiveComponent } = useRive({
    src: `${process.env.PUBLIC_URL}/assets/rive/logo-green.riv`,
    autoplay: true,
  });

  return createPortal (
    <div className={`loader loader--rive ${className}`}>
      <RiveComponent className="loader__rive" style={{ width: 360, height: 360 }} />
    </div>,
    modalRoot
  );
};

export default LoaderRive;
