import React, { forwardRef } from 'react';
// hooks
import { useLang, useProjects, useWindowDimensions } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// components
import Review from 'components/Review/Review';
import LinkBtn from 'components/LinkBtn/LinkBtn';
// scss
import './Reviews.scss';
// ===== text translate START =====
const dataToTranslate = {
  textTitle: "pages.home.views.reviews.title",
  textAllReviewsLink: "pages.home.views.reviews.all_reviews_link",
}
// ===== text translate END =====

const Reviews = forwardRef((props, ref) => {
  const { currentLang } = useLang();
  const { reviews } = useProjects();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textTitle, textAllReviewsLink } = translate ?? {};
  // ===== text translate END =====
  const { is768, is1920 } = useWindowDimensions();
  const reviewsData = distributeReviews(reviews, is768, is1920);
  const { reviewsData1, reviewsData2, reviewsData3 } = reviewsData ?? {}

  return (
    <section ref={ref}  className="reviews scroll-snap-section">
      <div className="reviews__container container">
        <h2 className="reviews__title">{textTitle}</h2>
        <div className="reviews__list-wrap">
          <ul className="reviews__list"> {reviewsData1?.map((i, idx) => ( <li key={idx} className="reviews__item"> <Review className='review--demo' data={i} demo={true} /> </li> ))} </ul>
          {is768 && <ul className="reviews__list"> {reviewsData2?.map((i, idx) => ( <li key={idx} className="reviews__item"> <Review className='review--demo' data={i} demo={true} /> </li> ))} </ul>}
          {is1920 && <ul className="reviews__list"> {reviewsData3?.map((i, idx) => ( <li key={idx} className="reviews__item"> <Review className='review--demo' data={i} demo={true} /> </li> ))} </ul>}
        </div>
        <LinkBtn className='reviews__link link--primary' type="link" to={"/projects"} text={textAllReviewsLink} />
      </div>
    </section>
  );
});

export default Reviews;


const distributeReviews = (reviews, is768, is1920) => {
  const maxPerColumn = 3; // Максимум по 3 елементи в кожну колекцію

  return reviews?.reduce((acc, review, index) => {
    if (is1920) {
      // Розподіл для is1920: три колекції
      if (index % 3 === 0) {
        acc.reviewsData1.push(review);
      } else if (index % 3 === 1) {
        acc.reviewsData2.push(review);
      } else if (index % 3 === 2) {
        acc.reviewsData3.push(review);
      }
    } else if (is768) {
      // Розподіл для is768: дві колекції
      if (index % 2 === 0) {
        acc.reviewsData1.push(review);
      } else {
        acc.reviewsData2.push(review);
      }
    } else {
      // За замовчуванням: перші три елементи в reviewsData1
      if (acc.reviewsData1.length < maxPerColumn) {
        acc.reviewsData1.push(review);
      }
    }
    return acc;
  }, { reviewsData1: [], reviewsData2: [], reviewsData3: [] });
};
