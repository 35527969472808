import React from 'react';
// components
import Logo from 'components/Logo/Logo';
import BurgerMenu from 'components/BurgerMenu/BurgerMenu';
import BurgerMenuBtn from 'components/BurgerMenu/BurgerMenuBtn';
import LangSwitcher from 'components/LangSwitcher/LangSwitcher';
// scss
import './Header.scss';

const Header = ({ isBurgerMenuOpen, setIsBurgerMenuOpen, className = "" }) => {
  return (
    <header className={`header ${className}`}>
      <div className="header__container container">
        <Logo className={'logo--header'} />
        <LangSwitcher isBurgerMenuOpen={isBurgerMenuOpen} />
        <BurgerMenuBtn isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />
        <BurgerMenu isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />
      </div>
    </header>
  );
};

export default Header;
