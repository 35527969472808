import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import { Navigation, Pagination, Keyboard, Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// PhotoSwipeLightbox
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
// hooks
import { useWindowDimensions } from "hooks";
// helpers
import { isAvailableArray } from "helpers";
// components
import ProjectSwiperItem from "components/ProjectSwiperItem/ProjectSwiperItem";
// scss
import './ProjectSwiperDemo.scss';

const optionsPhotoSwipeLightbox = {
  gallery: '#swiper-gallery',
  children: 'a',
  pswpModule: () => import('photoswipe'),
  // Динамічне налаштування для різних екранів
  paddingFn: () => {
    if (window.innerWidth >= 1200) {
      return { top: 20, bottom: 20, left: 60, right: 60 }; // Більше місця для великих екранів
    }
    return { top: 10, bottom: 10, left: 10, right: 10 }; // Менше місця для мобільних
  },
}

export default function ProjectSwiperDemo({ swiperListData }) {
  const { is768, is1200, is1920 } = useWindowDimensions();
  const className = "project-swiper-demo";
  let spaceBetween = 8;
  let slidesPerView = 1.2;

  if (is768) {
    spaceBetween = 24;
    slidesPerView = 1.8;
  }
  if (is1200) {
    spaceBetween = 30;
    slidesPerView = 1.6;
  }
  if (is1920) {
    spaceBetween = 52;
    slidesPerView = 2.8;
  }

  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox(optionsPhotoSwipeLightbox);
    lightbox.init();

    return () => { lightbox.destroy(); };
  }, []);

  return (
    <div className={`${className}__wrap`} id="swiper-gallery">
      <Swiper
        className={className}
        modules={[Navigation, Pagination, Keyboard, Autoplay]}
        pagination={{ clickable: true }}
        keyboard={{ enabled: true, }}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        centeredSlides={true}
        loop={true}
        speed={1000}
        autoplay={{ delay: 3200, disableOnInteraction: false, }}
      >
        {isAvailableArray(swiperListData)?.map((i, idx) => ( <SwiperSlide key={idx}>{<ProjectSwiperItem data={i} />}</SwiperSlide> ))}
      </Swiper>
    </div>
  );
}
