import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import { FreeMode, Navigation, Thumbs, Keyboard, Autoplay } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// PhotoSwipeLightbox
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
// hooks
import { useWindowDimensions } from 'hooks';
// helpers
import { isAvailableArray } from 'helpers';
// components
import ProjectSwiperItem from 'components/ProjectSwiperItem/ProjectSwiperItem';
import ProjectSwiperItemThumb from 'components/ProjectSwiperItem/ProjectSwiperItemThumb';
// scss
import './ProjectSwiperGallery.scss';

const optionsPhotoSwipeLightbox = {
  gallery: '#swiper-gallery',
  children: 'a',
  showHideAnimationType: 'fade',
  pswpModule: () => import('photoswipe'),
  loop: false, // Вимикає гортання по колу
  // showHideAnimationType: 'fade', // Тип анімації при зміні слайдів
  // showAnimationDuration: 1000, // Тривалість показу анімації в мілісекундах
  // hideAnimationDuration: 1000, // Тривалість приховування анімації
  // Динамічне налаштування для різних екранів
  paddingFn: () => {
    if (window.innerWidth >= 1200) {
      return { top: 20, bottom: 20, left: 60, right: 60 }; // Більше місця для великих екранів
    }
    return { top: 10, bottom: 10, left: 10, right: 10 }; // Менше місця для мобільних
  },
}

export default function ProjectSwiperGallery({ swiperListData, }) {
  const location = useLocation();
  const { is768, is1200 } = useWindowDimensions();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [slideHeight, setSlideHeight] = useState(600); // Стан для збереження висоти слайда
  const sliderRef = useRef(null); // Реф для доступу до контейнера
  const swiperRef = useRef(null); // Реф для доступу до swiperRef
  const className = "project-swiper-gallery";
  const className2 = "project-swiper-gallery-thumbs";
  const swiperDirection = is1200 ? "vertical" : "horizontal";
  const slidesPerViewThumbs = is768 ? 4 : 2;
  const slidesPerGroupThumbs = is768 ? 4 : 2;

  const updateSliderHeight = (swiper) => {
    if (!is1200) return;

    const currentSlide = swiper?.slides ? swiper?.slides[swiper?.activeIndex] : null ;
    const currentSlideItem = currentSlide?.children ? currentSlide?.children[0] : null;
    const currentSlideItemImg = currentSlideItem?.children ? currentSlideItem?.children[0] : null;
    const newSlideHeight = currentSlideItemImg?.offsetHeight;
    if (currentSlideItem) setTimeout(() => { setSlideHeight(newSlideHeight); }, 400);
  };

  useEffect(() => {
    // Оновлюємо висоту при зміні location
    if (swiperRef.current) {
      swiperRef?.current?.slideTo(0); // Повертаємось на перший слайд при зміні location
    }
  }, [location]); // Залежність від location

  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox(optionsPhotoSwipeLightbox);
    lightbox.init();

    return () => { lightbox.destroy(); };
  }, []);

  return (
    <div className={`${className}__wrap`} id='swiper-gallery' ref={sliderRef} style={{ maxHeight: slideHeight, "--wrap--height": slideHeight  }}>
      <Swiper
        style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff', }}
        className={`${className} gallery`}
        modules={[FreeMode, Navigation, Thumbs, Keyboard, Autoplay]}
        keyboard={{ enabled: true, }}
        navigation={true}
        spaceBetween={50}
        slidesPerGroup={1}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        direction={swiperDirection}
        speed={1000}
        autoplay={{ delay: 3200, disableOnInteraction: false, }}
        autoHeight={true}
        onInit={(swiper) => { 
          swiperRef.current = swiper; // Зберігаємо реф Swiper
          setTimeout(() => { updateSliderHeight(swiper); }, 100); // Мінімальна затримка
          setTimeout(() => { updateSliderHeight(swiper); }, 1000); // Мінімальна затримка
        }} 
        onSlideChange={(swiper) => updateSliderHeight(swiper)} // Обробник для зміни слайдів
      >
        {isAvailableArray(swiperListData)?.map((i, idx) => ( <SwiperSlide key={idx}>{<ProjectSwiperItem data={i} />}</SwiperSlide> ))}
      </Swiper>
      <Swiper
        className={className2}
        modules={[FreeMode, Navigation, Thumbs]}
        navigation={true}
        spaceBetween={10}
        slidesPerView={slidesPerViewThumbs}
        slidesPerGroup={slidesPerGroupThumbs}
        freeMode={true}
        onSwiper={setThumbsSwiper}
        watchSlidesProgress={true}
        direction={swiperDirection}
        speed={1000}
      >
        {isAvailableArray(swiperListData)?.map((i, idx) => ( <SwiperSlide key={idx}>{<ProjectSwiperItemThumb data={i} />}</SwiperSlide> ))}
      </Swiper>
    </div>
  );
}
