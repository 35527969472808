export const validateName = value => {
  const re = /^[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ'\s]+$/u;

  if (!re.test(String(value).toLowerCase())) {
    return 'A name must contain just A-Z, a-z, or А-Я, а-я';
  } else if (value.length < 2 || value.length > 40) {
    return 'A name must contain 2 to 40 characters.';
  } else return null;
};
export const validateEmail = value => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(value).toLowerCase())) {
    return "Email can contain only latin letters, numbers, '@' and '.'";
  } else return null;
};
export const validatePhone = value => {
  const re = /^\+?[1-9]\d{1,14}$/; // міжнародний формат телефону
  // const re = /[0-9()+\- ]/;

  if (!re.test(String(value).toLowerCase())) {
    return "Enter valid phone number";
  } else return null;
};
