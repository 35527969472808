import { createSlice } from '@reduxjs/toolkit';
import { getCategories, getLastProjects, getProjects } from './operations';

const handlePending = state => {
  state.status = 'pending';
  state.error = null;
};
const handleRejected = (state, action, status = 'rejected') => {
  state.status = status;
  state.error = action.payload;
};
const handleFulfilled = (state, status = 'fulfilled') => {
  state.status = status;
  state.error = null;
};

const initialState = {};

const projectsSlice = createSlice({
  name: 'projects',
  initialState: initialState,
  extraReducers: (builder) =>
    builder
      .addCase(getProjects.pending, (state, action) => { handlePending(state); })
      .addCase(getProjects.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getProjects.fulfilled, (state, action) => { handleFulfilled(state);
        state.list = action.payload;
      })
      .addCase(getLastProjects.pending, (state, action) => { handlePending(state); })
      .addCase(getLastProjects.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getLastProjects.fulfilled, (state, action) => { handleFulfilled(state);
        state.listLast = action.payload;
      })
      .addCase(getCategories.pending, (state, action) => { handlePending(state); })
      .addCase(getCategories.rejected, (state, action) => { handleRejected(state, action); })
      .addCase(getCategories.fulfilled, (state, action) => { handleFulfilled(state);
        state.categories = action.payload;
      })
      // .addCase(getProjectById.pending, (state, action) => { handlePending(state); })
      // .addCase(getProjectById.rejected, (state, action) => { handleRejected(state, action); })
      // .addCase(getProjectById.fulfilled, (state, action) => { handleFulfilled(state);
      //   state.item = action.payload;
      // })
});

export const projectsReducer = projectsSlice.reducer;
