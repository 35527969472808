import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
// hooks
import { useWindowDimensions } from 'hooks';
// layouts
import { PublicLayout } from 'layouts';
// pages
import HomePage from 'pages/HomePage/HomePage';
import ProjectPage from 'pages/ProjectPage/ProjectPage';
import ProjectsPage from 'pages/ProjectsPage/ProjectsPage';
// ===== PUBLIC Pages START =====

const App = () => {
  const location = useLocation();
  const { is1024, height } = useWindowDimensions();
  const isHomePage = Boolean(!location?.pathname?.replace("/", ""));

  useEffect(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }, [location]);
  useEffect(() => { if (is1024) document.querySelector('html').style.setProperty("--window-height", `${height}px`); }, [is1024, height]);
  useEffect(() => {
    const currentHeight = window.innerWidth >= 1024 ? window.innerHeight : window.innerHeight + 40;
    document.querySelector('html').style.setProperty("--window-height", `${currentHeight}px`);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<PublicLayout isHomePage={isHomePage} />}>
        <Route index element={<HomePage />}></Route>
        <Route path="/projects" element={<ProjectsPage />}></Route>
        <Route path="/projects/:projectId" element={<ProjectPage />}></Route>
      </Route>
    </Routes>
  );
};

export default App;
