import React from 'react';
// scss
import './Marquee.scss';

const Marquee = () => {
  return (
    <div className="marquee">
      <div className="marquee__inner">
        <p className="marquee__track marquee__track--1"> 2BOT AGENCY 2BOT AGENCY </p>
        <p className="marquee__track marquee__track--2"> 2BOT AGENCY 2BOT AGENCY </p>
      </div>
    </div>
  );
};

export default Marquee;
