import { useSelector } from "react-redux";
// redux
import { selectLang } from "redux/lang/langSelectors";

const useLang = () => {
  const currentLang = useSelector(selectLang) || "ua";
  let currentLangAttribute = "uk-UA";
  if (currentLang === "en") currentLangAttribute = "en-US";

  return {
    currentLang,
    currentLangAttribute,
  };
};

export default useLang;
