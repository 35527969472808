import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// hooks
import { useLang } from 'hooks';
// helpers
import { getCurrentLangObject } from 'helpers';
// components
import SvgUse from 'components/SvgUse/SvgUse';
import Rating from 'components/Rating/Rating';
// scss
import './Review.scss';

const Review = ({ data, className = "", demo }) => {
  const location = useLocation();
  const { currentLang } = useLang();
  const { id, customer_name, customer_img, review_text, review_rating, url = null, review_link = null } = data ?? {}
  const textText = getCurrentLangObject(review_text, currentLang);
  const review_url = demo ? url : review_link;
  const customer_img_url = `${process.env.PUBLIC_URL}/assets/projects/${id}/${customer_img}`;

  return (
    <Link className={`review ${className}`} to={review_url} state={{from: location}} aria-disabled={!review_url}>
      {customer_img 
        ? <img loading='lazy' className='review__img' src={customer_img_url} alt="customer_name" width={40} height={40} />
        : <figure className='review__img'></figure>
      }
      {review_url && <SvgUse className={"review__icon--arrow"} icontag={'arrow-top-right'} />}
      <p className="review__name">{customer_name}</p>
      <Rating rating={review_rating} />
      <p className="review__text">{textText}</p>
    </Link>
  )
}

export default Review