import React, { useState } from "react";
import { useDispatch } from "react-redux";
// redux
import { changeLang } from "redux/lang/langSlice";
// hooks
import { useLang } from "hooks";
// helpers
import { compareStrings, isAvailableArray } from "helpers";
// common
import { availableLanguages } from "common/constants";
// scss
import './LangSwitcher.scss';

const LangSwitcher = ({ isBurgerMenuOpen, className = "" }) => {
  const { currentLang } = useLang();
  const [showLang, setShowLang] = useState(false);
  const dispatch = useDispatch();

  const availableLanguagesWithOutActive = isAvailableArray(availableLanguages)?.filter((i) => compareStrings(i, currentLang, false));

  const handleClick = (newCurrentLang) => {
    dispatch(changeLang(newCurrentLang));
    setShowLang(false);
  };

  return (
    <div className={`lang ${className} ${showLang ? "active" : ""}`}>
      <button className="lang__btn lang__btn--arrow" type="button" aria-label="Select lang" onClick={() => setShowLang(!showLang)} disabled={isBurgerMenuOpen} >
        {currentLang}
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M5.21279 6.59917C4.92708 6.32827 4.46386 6.32827 4.17815 6.59917C3.89244 6.87007 3.89244 7.30928 4.17815 7.58018L8.83405 11.9948C9.11976 12.2656 9.58299 12.2656 9.8687 11.9947L14.5246 7.58018C14.8103 7.30928 14.8103 6.87007 14.5246 6.59917C14.2389 6.32827 13.7757 6.32827 13.49 6.59917L9.35137 10.4765L5.21279 6.59917Z" fill="white"/>
        </svg>
      </button>
      <ul className="lang__list">
        {availableLanguagesWithOutActive?.map((i, idx) => (
          <li key={idx} className="lang__item">
            <button className="lang__btn" type="button" aria-label="Select lang" onClick={() => handleClick(i)}> {i} </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LangSwitcher;
