import React, { useEffect, useRef, useState } from 'react';
// hooks
import { useLang } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// views
import Hero from 'views/Hero/Hero';
import Benefits from 'views/Benefits/Benefits';
import Services from 'views/Services/Services';
import Portfolio from 'views/Portfolio/Portfolio';
import Reviews from 'views/Reviews/Reviews';
// components
import Modal from 'components/Modal/Modal';
import OrderBtn from 'components/OrderBtn/OrderBtn';
import FeedbackForm from 'components/forms/FeedbackForm/FeedbackForm';
import Notification from 'components/Notification/Notification';
// ===== text translate START =====
const dataToTranslate = {
  textNotificationsSuccess: "notifications.success",
  textNotificationsError: "notifications.error",
}
// ===== text translate END =====

const HomePage = () => {
  const { currentLang } = useLang();
  const [isVisible, setIsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [textNotification, setTextNotification] = useState("Have a good day!");
  const [classNameNotification, setClassNameNotification] = useState("success");
  const heroRef = useRef(null);
  const benefitsRef = useRef(null);
  const servicesRef = useRef(null);
  const portfolioRef = useRef(null);
  const reviewsRef = useRef(null);

  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textNotificationsSuccess, textNotificationsError, } = translate ?? {};
  // ===== text translate END =====

  function setNotification(res) {
    if (res === "ok") {
      setTextNotification(textNotificationsSuccess);
      setClassNameNotification("success")
    } else {
      setTextNotification(textNotificationsError);
      setClassNameNotification("error")
    }
    setShowNotification(true);


    // Автозакриття через 5 секунд
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  }

  useEffect(() => {
    const sectionRefs = [heroRef, benefitsRef, servicesRef, portfolioRef, reviewsRef];

    // Функція обробки перетину елементів з екраном
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const section = entry.target;
            // Логіка: показувати кнопку на 2-й, 3-й, і 5-й секціях
            if (
              section === benefitsRef.current || // 2-я секція
              section === servicesRef.current || // 3-я секція
              section === reviewsRef.current // 5-я секція
            ) {
              setIsVisible(true);
            } else {
              setIsVisible(false);
            }
          }
        });
      },
      {
        threshold: 0.1, // Поріг перетину 10%
      }
    );

    // Додаємо кожну секцію до спостерігача
    sectionRefs.forEach((ref) => { if (ref.current) observer.observe(ref.current); });
    // Прибираємо спостереження за секціями після розмонтування
    return () => { sectionRefs.forEach((ref) => { if (ref.current) observer.unobserve(ref.current); }); };
  }, []);

  return (
    <>
      <Hero setShowModal={setShowModal} ref={heroRef} />
      <Benefits ref={benefitsRef} />
      <Services ref={servicesRef} />
      <Portfolio ref={portfolioRef} />
      <Reviews ref={reviewsRef} />
      <OrderBtn isVisible={isVisible} setShowModal={setShowModal} />
      <Modal showModal={showModal} setShowModal={setShowModal}>
        {showModal && ( <FeedbackForm showModal={showModal} setShowModal={setShowModal} setNotification={setNotification} /> )}
      </Modal>
      {showNotification && <Notification text={textNotification} className={classNameNotification} showNotification={showNotification} setShowNotification={setShowNotification} />}
    </>
  );
};

export default HomePage;
