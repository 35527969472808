import React, { useState } from 'react';
// hooks
import { useLang, useProjects } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
import { validateEmail, validateName, validatePhone } from 'helpers/validation/validate';
// services
import sendMessage from 'services/sendMessage';
// components
import LinkBtn from 'components/LinkBtn/LinkBtn';
import InputDropdown from 'components/InputDropdown/InputDropdown';
// scss
import './FeedbackForm.scss';
// ===== text translate START =====
const dataToTranslate = {
  textTitle: "form_title",
  textName: "name",
  textEmail: "email",
  textPhone: "phone",
  textServices: "services",
  textSend: "send",
}
// ===== text translate END =====

const FeedbackForm = ({ setShowModal, setNotification }) => {
  const { currentLang } = useLang();
  const { categories } = useProjects();
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [options, setOptions] = useState(categories?.map(i => ({ ...i, checked: false })));
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [nameDirty, setNameDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [phoneDirty, setPhoneDirty] = useState(false);
  const checkedOptions = options?.filter(i => i?.checked);
   // ===== text translate START =====
   const translate = getTranslate(dataToTranslate, currentLang);
   const {
    textTitle,
    textName,
    textEmail,
    textPhone,
    textServices,
    textSend,
   } = translate ?? {};
   // ===== text translate END =====

  function handleBlur(e) {
    switch (e.target.name) {
      case 'name':
        setNameDirty(true);
        break;
      case 'email':
        setEmailDirty(true);
        break;
      case 'tel':
        setPhoneDirty(true);
        break;

      default:
        return;
    }
  };
  function handleName(e) {
    setNameValue(e.target.value);
    setNameError(validateName(e.target.value));
  };
  function handleEmail(e) {
    setEmailValue(e.target.value);
    setEmailError(validateEmail(e.target.value));
  };
  function handlePhone(e) {
    setPhoneValue(e.target.value);
    setPhoneError(validatePhone(e.target.value));
  };
  function handleNameKeyPress (e) {
    const regex = /^[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ'\s]+$/u;
    const char = String.fromCharCode(e.charCode);
    if (!regex.test(char)) {
      e.preventDefault();
    }
  };
  function handlePhoneKeyPress (e) {
    const regex = /[0-9+\- ]/;
    const char = String.fromCharCode(e.charCode);
    if (!regex.test(char)) {
      e.preventDefault();
    }
  };
  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      name: nameValue,
      email: emailValue,
      phone: phoneValue,
      options: checkedOptions?.map(i => i[currentLang])?.join(", "),
    }

    const formData = new FormData();
    // Додаємо кожне поле з об'єкта до FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const res = await sendMessage(formData);
    setNotification(res);
    handleReset();
  }
  function handleReset(e) {
    setNameValue("");
    setEmailValue("");
    setPhoneValue("");
    setNameError(null);
    setEmailError(null);
    setPhoneError(null);
    setOptions(categories?.map(i => ({ ...i, checked: false })));
    setShowModal(false);
  }

  return (
    <form className="feedback-form" id="feedbackForm" onSubmit={handleSubmit}>
      <div className="feedback-form__header">
        <h2 className="feedback-form__title">{textTitle}</h2>
        <button className="feedback-form__btn-close" type="button" aria-label='Close form' onClick={() => {setShowModal(false); handleReset()}} >
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" >
            <path fillRule="evenodd" clipRule="evenodd" d="M1.98639 0.716045C1.70961 0.438029 1.27141 0.448051 1.00764 0.738431C0.743862 1.02881 0.7544 1.48959 1.03118 1.7676L6.20288 6.9625L1.01632 12.0768C0.736984 12.3522 0.722194 12.8129 0.983284 13.1057C1.24438 13.3985 1.68248 13.4125 1.96181 13.1371L7.20392 7.96804L12.3363 13.1234C12.613 13.4014 13.0512 13.3914 13.315 13.101C13.5788 12.8106 13.5683 12.3499 13.2915 12.0719L8.21422 6.97181L13.3269 1.93039C13.6062 1.65495 13.621 1.1943 13.3599 0.901507C13.0988 0.608712 12.6607 0.594645 12.3814 0.870089L7.21318 5.96628L1.98639 0.716045Z" fill="white" />
          </svg>
        </button>
      </div>
      <div className="feedback-form__body">
        <div className={`feedback-for__label-wrap ${(nameError && nameDirty) ? "error" : ""}`} data-error={nameError}>
          <label className='feedback-for__label' htmlFor="name">{textName}</label>
          <input className={`feedback-for__input ${(nameError && nameDirty) ? "error" : ""}`} type="name" name="name" placeholder='Ethan Hunt' maxLength={40} required value={nameValue} onBlur={handleBlur} onChange={handleName} onKeyPress={handleNameKeyPress} />
        </div>

        <div className={`feedback-for__label-wrap ${(emailError && emailDirty) ? "error" : ""}`} data-error={emailError}>
          <label className='feedback-for__label' htmlFor="email">{textEmail}</label>
          <input className={`feedback-for__input ${(emailError && emailDirty) ? "error" : ""}`} type="email" name="email" placeholder='info@2botagency.com' maxLength={320} required value={emailValue} onBlur={handleBlur} onChange={handleEmail} />
        </div>

        <div className={`feedback-for__label-wrap ${(phoneError && phoneDirty) ? "error" : ""}`} data-error={phoneError}>
          <label className='feedback-for__label' htmlFor="tel">{textPhone}</label>
          <input className={`feedback-for__input ${(phoneError && phoneDirty) ? "error" : ""}`} type="tel" name="tel" placeholder='+ 380 (000) 000-00-00' maxLength={20} required value={phoneValue} onBlur={handleBlur} onChange={handlePhone} onKeyPress={handlePhoneKeyPress} />
        </div>

        <div className="feedback-for__label-wrap">
          <label className='feedback-for__label'>{textServices}</label>
          <InputDropdown showListInit={false} options={options} setOptions={setOptions} checkedOptions={checkedOptions} />
        </div>
      </div>
      <div className="feedback-form__footer">
        <LinkBtn className={"feedback-form__btn-submit button--primary"} type={"submit"} text={textSend} />
      </div>
    </form>
  );
};

export default FeedbackForm;
