import React from 'react';
// components
import Nav from 'components/Nav/Nav';
// scss
import './BurgerMenu.scss';

const BurgerMenu = ({ isBurgerMenuOpen, setIsBurgerMenuOpen }) => {
  function onBackdropClick(e) {
    if (e.target.classList.contains('nav__link')) return
    setIsBurgerMenuOpen();
  }

  return (
    <div className={`burger-menu ${isBurgerMenuOpen ? '' : 'isHidden'}`} aria-hidden={!isBurgerMenuOpen} >
      <div className="burger-menu__backdrop backdrop" onClick={(e) => onBackdropClick(e)}>
        <div className="burger-menu__container container">
          <Nav className={'burger-menu__nav'} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
