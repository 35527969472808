import { createAsyncThunk } from '@reduxjs/toolkit';
import { setErrorRes } from 'helpers';

export const getProjects = createAsyncThunk(
  'projects/getProjects',
  async (_, thunkAPI) => {
    try {
      // Використовуємо fetch для отримання даних
      const response = await fetch('/data/projects.json');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data; // Повертаємо дані
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
