import React from 'react';
import { createPortal } from 'react-dom';
// scss
import './Notification.scss';

const modalRoot = document.querySelector('#modal-root');

const Notification = ({ showNotification, setShowNotification, text = "", className = 'success' }) => {
  return createPortal(
    <div className={`notification ${showNotification ? '' : 'isHidden'} ${className}`} aria-hidden={!showNotification}>
      <div className="notification__container container">
        <div className="notification__box">
          <p className="notification__text">{text}</p>
          <button className='notification__btn-close button' aria-label='Close notification' onClick={() => setShowNotification(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M1.68884 0.808873C1.47016 0.589211 1.12394 0.59713 0.915527 0.82656C0.707118 1.05599 0.715445 1.42005 0.934125 1.63971L5.02031 5.74422L0.922388 9.78504C0.701684 10.0027 0.689998 10.3666 0.896287 10.598C1.10258 10.8293 1.44872 10.8404 1.66943 10.6228L5.81124 6.5387L9.86633 10.612C10.085 10.8316 10.4312 10.8237 10.6396 10.5943C10.8481 10.3649 10.8397 10.0008 10.621 9.78114L6.60948 5.75158L10.649 1.76833C10.8697 1.5507 10.8814 1.18674 10.6751 0.955406C10.4688 0.724068 10.1227 0.712954 9.90198 0.930583L5.81855 4.95711L1.68884 0.808873Z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default Notification