import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// components
import SvgUse from 'components/SvgUse/SvgUse';
// scss
import './LinkBtn.scss';

const LinkBtn = ({
  type = 'button',
  text,
  className = '',
  onClick = () => {},
  to = '#',
  icontag,
  disabled = false,
  style,
  ariaLabel = "",
  target = "_self"
}) => {
  const location = useLocation();

  return type === 'link' ? (
    disabled ? (
      <Link className={`${className} link`} aria-disabled="true" style={style} aria-label={ariaLabel} target={target} >
        {text && <span>{text}</span>}
        {icontag && <SvgUse icontag={icontag} />}
      </Link>
    ) : (
      <Link className={`${className} link`} to={to} style={style} aria-label={ariaLabel} target={target} state={{ from: location }}>
        {text && <span>{text}</span>}
        {icontag && <SvgUse icontag={icontag} />}
      </Link>
    )
  ) : (
    <button
      className={`${className} button`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={style}
      aria-label={ariaLabel}
    >
      {text && <span>{text}</span>}
      {icontag && <SvgUse icontag={icontag} />}
    </button>
  );
};

export default LinkBtn;
