import React from 'react';
// components
import SvgUse from 'components/SvgUse/SvgUse';
// scss
import './Rating.scss';

const Rating = ({ rating = 0 }) => {
  // Генеруємо унікальний ID для градієнта кожного компонента
  const uniqueId = `gradient-${Math.random().toString(36).substr(2, 9)}`;
  // Функція для вибору класу на основі поточної оцінки
  const getStarClass = index => {
    if (rating >= index + 1) {
      return 'rating__star rating__star--filled'; // Повністю заповнена зірка
    } else if (rating > index && rating < index + 1) {
      return 'rating__star rating__star--partial'; // Частково заповнена
    } else {
      return 'rating__star'; // Порожня зірка
    }
  };
  // Створення градієнта для часткових зірок
  const getGradientOffset = () => {
    const fractionalPart = rating - Math.floor(rating); // Визначаємо дробову частину
    return `${fractionalPart * 100}%`; // Конвертуємо в відсотки
  };

  return (
    <div className="review__rating rating">
      {[...Array(5)].map((_, idx) => (
        <SvgUse 
          key={idx} 
          className={getStarClass(idx)} 
          icontag={'star'} 
          style={{ fill: rating > idx && rating < idx + 1 ? `url(#${uniqueId})` : undefined, }}
        />
      ))}
      <svg width="0" height="0">
        <defs>
          <linearGradient id={uniqueId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset={getGradientOffset()} style={{ stopColor: '#2dbc55', stopOpacity: 1 }} />
            <stop offset={getGradientOffset()} style={{ stopColor: 'transparent', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Rating;
