export function checkIsEmptyObject(obj) {
  return Object.keys(obj)?.length === 0;
}

export function checkIsEmptyArray(array) {
  return array?.length === 0;
}

export function checkIsEmptyObjectOrArray(obj) {
  for (var i in obj) return false;
  return true;
}
