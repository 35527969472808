import React from 'react';
import { Link } from 'react-router-dom';
// hooks
import { useLang } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// scss
import './Contacts.scss';
// ===== text translate START =====
const dataToTranslate = {
  textContacts: "contacts",
  textAddress: "address",
}
// ===== text translate END =====

const Contacts = ({ className = '' }) => {
  const { currentLang } = useLang();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textContacts, textAddress, } = translate ?? {};
  // ===== text translate END =====

  return (
    <div className={`${className} contacts`}>
      <ul className="contacts__list">
        <li className="contacts__item">
          <h2 className="contacts__title">{textContacts}</h2>
          <Link className="contacts__link" to="tel:+380636411092" target='_blank'>+380636411092</Link>
        </li>
        <li className="contacts__item">
          <h2 className="contacts__title">Email</h2>
          <Link className="contacts__link" to="mailto:2bot.agency@gmail.com" target='_blank'>2bot.agency@gmail.com</Link>
        </li>
        <li className="contacts__item">
          <h2 className="contacts__title">{textAddress}</h2>
          <Link className="contacts__link" to="https://maps.app.goo.gl/CipRvwsXPbHfU6bi7" target='_blank'>вул. Водогінна 2, м.Львів</Link>
        </li>
      </ul>
    </div>
  );
};

export default Contacts;
