import React, { forwardRef} from 'react';
// hooks
import { useLang } from 'hooks';
// helpers
import { getTranslate } from 'helpers';
// components
import SvgUse from 'components/SvgUse/SvgUse';
// scss
import './Hero.scss';

// ===== text translate START =====
const dataToTranslate = {
  textTitle: "pages.home.views.hero.title",
  textSubtitle: "pages.home.views.hero.subtitle",
  textSubtitle2: "pages.home.views.hero.subtitle2",
  textGetStarted: "get_started",
}
// ===== text translate END =====

const Hero = forwardRef(({ setShowModal }, ref) => {
  const { currentLang } = useLang();
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { 
    textTitle,
    textSubtitle,
    textSubtitle2,
    textGetStarted,
  } = translate ?? {};
  // ===== text translate END =====

  return (
    <section ref={ref} className="hero scroll-snap-section scrollSnap">
      <div className="hero__container container">
        <div className="hero__box">
          <div className="hero__desc">
            <h1 className="hero__title"> {textTitle}</h1>
            <p className="hero__text">{textSubtitle}</p>
            {textSubtitle2 && <p className="hero__text">{textSubtitle2}</p>}
            <button className='hero__btn hero-btn' type="button" onClick={() => setShowModal(true)}>
              <span className='hero-btn__text'> {textGetStarted} </span>
              <span className='hero-btn__arrow'> <SvgUse icontag={"arrow"} /> </span>
            </button>
          </div>
        </div>
      </div>
      <video 
        className="hero__video video--bg" 
        preload="none" 
        width="600" 
        loop 
        muted 
        autoPlay
        playsInline
        type='video/mp4'
      >
        <source type="video/mp4" src="/assets/video/homepage.mp4" />
      </video>
    </section>
  );
});

export default Hero;