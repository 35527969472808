import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// hooks
import { useLang, useProjects } from 'hooks';
// helpers
import { compareStrings, getTranslate } from 'helpers';
// components
import Modal from 'components/Modal/Modal';
import OrderBtn from 'components/OrderBtn/OrderBtn';
import ProjectCase from 'components/ProjectCase/ProjectCase';
import FeedbackForm from 'components/forms/FeedbackForm/FeedbackForm';
import Notification from 'components/Notification/Notification';
// ===== text translate START =====
const dataToTranslate = {
  textNotificationsSuccess: "notifications.success",
  textNotificationsError: "notifications.error",
}
// ===== text translate END =====

const ProjectPage = () => {
  const { currentLang } = useLang();
  const { projectId } = useParams();
  const { projects } = useProjects();
  const [isVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [textNotification, setTextNotification] = useState("Have a good day!");
  const [classNameNotification, setClassNameNotification] = useState("success");
  const project = projects?.find(i => compareStrings(i?.id, projectId));
  // ===== text translate START =====
  const translate = getTranslate(dataToTranslate, currentLang);
  const { textNotificationsSuccess, textNotificationsError, } = translate ?? {};
  // ===== text translate END =====

  function setNotification(res) {
    if (res === "ok") {
      setTextNotification(textNotificationsSuccess);
      setClassNameNotification("success")
    } else {
      setTextNotification(textNotificationsError);
      setClassNameNotification("error")
    }
    setShowNotification(true);

    // Автозакриття через 5 секунд
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  }

  return (
    <>
      <ProjectCase data={project} projects={projects} />
      <OrderBtn isVisible={isVisible} setShowModal={setShowModal} />
      <Modal showModal={showModal} setShowModal={setShowModal}>
        {showModal && ( <FeedbackForm showModal={showModal} setShowModal={setShowModal} setNotification={setNotification} /> )}
      </Modal>
      {showNotification && <Notification text={textNotification} className={classNameNotification} showNotification={showNotification} setShowNotification={setShowNotification} />}
    </>
  )
}

export default ProjectPage