import { useSelector } from 'react-redux';
// redux
import {
  selectProjectsCategories,
  selectProjectsList,
  selectProjectsListLast,
} from 'redux/projects/projectsSelectors';

const useProjects = () => {
  const projects = useSelector(selectProjectsList);
  const lastProjects = useSelector(selectProjectsListLast);
  const categories = useSelector(selectProjectsCategories);

  const reviews = projects?.map(i => ({id: i?.id, ...i?.review, url: `/projects/${i.id}`}));

  return {
    projects,
    reviews,
    lastProjects,
    categories,
  };
};

export default useProjects;
